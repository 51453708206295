<template>
  <div>
    <b-container class="mt-4">
      <b-row class="mt-3 mb-4">
        <b-col class="text-center h7">
          Personal data
        </b-col>
      </b-row>
      <b-row
        class="m-0 content-holder p-3"
      >
        <b-col class="mt-3 mb-4">
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">First name
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="user.first_name"
              placeholder="John"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Last name
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="user.last_name"
              placeholder="Doe"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Email
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="user.email"
              placeholder="john@doe.com"
            />
          </b-col>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-4">
      <b-row class="mt-3 mb-4">
        <b-col class="text-center h7">
          Company data
        </b-col>
      </b-row>
      <b-row
        v-if="business.id"
        class="m-0 content-holder p-3"
      >
        <b-col class="mt-3 mb-4">
          <b-row>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">Company name
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTextInput
                v-model="business.name"
                placeholder="Apollo 12 LLC."
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Company one-liner
              </label>
              <FormTextArea
                v-model="business.oneliner"
                name="title"
                :rows="1"
                placeholder="e.g. The Force is strong with this company. May the Force be with you!"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Description
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Give a short summary about the company
              </p>
              <FormTextArea
                v-model="business.description"
                name="description"
                required
                :rows="3"
                placeholder="e.g We're the best company the world has ever seen. We make a huge impact to the world!"
              />
            </b-col>
            <b-col
              class="mb-2"
              cols="12"
              xl="7"
            >
              <label class="h8">Company logo</label>
              <p class="h9">
                If you don't want to add your logo, you'll get a cute illustration instead
              </p>
              <b-form-group>
                <b-img
                  v-if="business.logo"
                  :src="business.logo"
                  class="company_logo"
                  rounded="circle"
                />
                <label
                  for="company_logo"
                  class="company_logo_upload mt-2"
                  :style="{ backgroundImage: 'url(' + business.logo + ')' }"
                />
                <b-form-file
                  id="company_logo"
                  class="company_logo"
                  :state="Boolean(business.logo)"
                  accept=".jpg, .jpeg, .png, .gif"
                  name="company_logo"
                  size="sm"
                  placeholder="Add a logo"
                  drop-placeholder="Drop file here..."
                  @input="uploadPic"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">
                Website(URL)
                <span class="text-primary">*</span>
              </label>
              <FormTextInput
                v-model="business.website"
                name="website"
                placeholder="https://bestcompany.com"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">HQ
                <span class="text-primary pl-1">*</span>
              </label>
              <b-form-select
                v-model="business.headquarters"
                :options="countryOptions"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Previous experience
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="business.previous_experiences"
                name="previous_experiences"
                :options="previousExperienceOptions"
                button-text="Select previous experiences that apply"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">HR structures
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="business.hr_structures"
                name="hr_structures"
                :options="hrStructureOptions"
                button-text="Select HR structures that apply"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Industries
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="business.industries"
                name="industries"
                :options="industryOptions"
                button-text="Select industries that apply"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8"># of Employees
                <span class="text-primary pl-1">*</span>
              </label>
              <BadgeCheckbox
                v-model="business.employees"
                name="employees"
                :options="employeesOptions"
                required
                :limit="1"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <b-btn
                variant="primary"
                type="submit"
                @click="saveCompany"
              >
                Save
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormTextInput from '@/components/form/TextInput'
import FormTextArea from '@/components/form/TextArea'
import FormTags from '@/components/FormTags'
import BadgeCheckbox from '@/components/form/BadgeCheckbox'
import axios from '@/helpers/axios'

export default {
  name: 'BusinessEdit',
  components: {
    FormTextInput,
    FormTags,
    BadgeCheckbox,
    FormTextArea,
  },
  data () {
    return {
      business: {},
      localUser: {},
      countryOptions: [],
      industryOptions: [],
      previousExperienceOptions: [],
      hrStructureOptions: [],
      employeesOptions: [
        { text: '2-10', value: '2-10' },
        { text: '11-50', value: '11-50' },
        { text: '51-200', value: '51-200' },
        { text: '201-500', value: '201-500' },
        { text: '501-1000', value: '501-1000' },
        { text: '1001-5000', value: '1001-5000' },
        { text: '5001-10000', value: '5001-10000' },
        { text: '10000+', value: '10000+' },
      ],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created () {
    axios
      .get(`/v2/businesses/${this.$store.state.auth.selectedBusiness.id}`)
      .then(({ data }) => {
        data.logo = data.logo || '/images/file_upload_company.svg'
        data.industries = (data.industries || []).map(r => r.id)
        data.previous_experiences = (data.previous_experiences || []).map(r => r.id)
        data.hr_structures = (data.hr_structures || []).map(r => r.id)
        this.business = data
        axios.get('/public/v2/values/countries')
          .then(({ data }) => {
            let temp = this.mapValues(data)
            temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
            temp.unshift({ value: null, text: 'Select country' })
            // Oke, that's going to be dirty hack
            // Buuut.. Basically, coz this field expects stuff like "Estonia" etc
            // And only field which requires it all is "hq"
            // But countries list returns UUIDs as value
            // Then we gonna map labels to values and be done with it
            // At least until we do full DB migration
            // Same thing is done in src/modules/admin/views/BusinessForm.vue
            temp = temp.map(v => {
              return {
                value: v.label,
                label: v.label,
                text: v.text,
              }
            })
            this.countryOptions = temp
          })
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(industry => industry.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(industry => industry.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.industryOptions = temp
      })

    axios.get('/public/v2/values/previous_experiences')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        const temp = this.mapValues(data)
        // Sorting array by alphabetical order
        this.previousExperienceOptions = temp.sort((a, b) => a.label.localeCompare(b.label))
      })

    axios.get('/public/v2/values/hr_structures')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        const temp = this.mapValues(data)
        // Sorting array by alphabetical order
        this.hrStructureOptions = temp.sort((a, b) => a.label.localeCompare(b.label))
      })
  },

  methods: {
    mapValues (data) {
      const dataArr = data.map(option => {
        option.value = option.id
        option.text = option.label
        return option
      })
      return dataArr
    },
    uploadPic (event) {
      const form = new FormData()
      form.set('file', event)
      axios.post('/public/v2/upload', form)
        .then(({ data }) => {
          this.business.logo = data
        })
    },
    saveCompany () {
      axios.put(`/v2/users/${this.user.id}`, this.user)
        .then(() => {
          this.$store.dispatch('auth/self')
        })
      const sendData = { ...this.business }
      sendData.admins = sendData.admins.map(admin => admin.id)
      sendData.users = sendData.users.map(user => user.id)
      axios.put(`/v2/businesses/${this.business.id}`, sendData)
        .then(() => {
          this.$store.dispatch('auth/self')

          this.$bvModal.msgBoxOk('Your profile has been updated!', {
            title: ' ',
            size: 'md',
            buttonSize: 'lg',
            headerClass: 'p-4 text-center',
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
            .then(() => {
              this.$router.push(`/business/${this.$route.params.id}`)
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .borderless-tags {
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .select-small-font {
    font-size: 0.9rem;
  }
  .content-holder {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
  }

  .company_logo {
    display: none;
    cursor: pointer;
  }
  .company_logo_upload {
    height: 100px;
    width: 200px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 100%;
  }

  .answer-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px #EDE1DF;
    border: 2px solid transparent;
    border-radius: 12px;
    color: #323031;
    font-size: 16px;
    min-height: 80px;
    &:hover {
      box-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
    }
    &.active {
      border: 2px solid #EE8B7B;
    }
  }
  .second-headline {
    font-size: 18px;
    line-height: 140%;
  }
</style>
